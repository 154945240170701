<template>
  <v-row>
    <v-dialog
      v-model="open"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card flat>
        <v-card-title>
          {{ $t("schedule.master_event_subjects") }}
          <div style="position: absolute; right: 25px;">
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-form ref="form" lazy-validation>
            <v-row class="mt-2">
              <v-col cols="12" class="text-subtitle-1">
                {{ $t("schedule.addressed_to") }}
              </v-col>
              <v-col cols="12">
                <v-radio-group v-model="radio" column hide-details>
                  <v-radio :label="$t('app.all')" value="all" />
                  <v-radio :label="$t('schedule.some')" value="some">
                    <template #label>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model="idGrade"
                            :label="$t('app.grade')"
                            :loading="loadingGrade"
                            :items="listGrade"
                            :rules="[
                              v =>
                                radio === 'all'
                                  ? true
                                  : !!v || $t('app.required')
                            ]"
                            :disabled="radio === 'all' ? true : false"
                            item-value="id"
                            item-text="grade"
                            dense
                            outlined
                            class="mt-5"
                            @change="getClass"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model="bodyRequest.class"
                            :label="$t('app.class')"
                            :loading="loadingClass"
                            :items="listClass"
                            :disabled="!idGrade"
                            :rules="
                              radio === 'all' && !idGrade
                                ? []
                                : [v => !!v.length || $t('app.required')]
                            "
                            item-value="id"
                            item-text="name"
                            dense
                            outlined
                            class="mt-5"
                            multiple
                          >
                            <template #selection="{ item, index}">
                              <span v-if="index === 0" class="mr-2">{{
                                item.name
                              }}</span>
                              <span v-if="index === 1" class="grey--text"
                                >(+{{ bodyRequest.class.length - 1 }})</span
                              >
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6">
                <v-textarea
                  v-model="bodyRequest.name"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('schedule.event_name')"
                  auto-grow
                  rows="1"
                  class="mt-10"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="idSchoolYear"
                  :items="listSchoolYear"
                  :label="$t('master_data.student.school_year')"
                  :item-text="item => `${item.start_year} / ${item.end_year}`"
                  item-value="id"
                  outlined
                  dense
                  class="mt-10"
                >
                  <template #item="{ item }">
                    <span class="caption">
                      {{ item.start_year }} / {{ item.end_year }}, semester
                      {{ item.semester }}
                    </span>
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          :color="schoolYearInfo(item.status).color"
                          small
                          class="ml-1"
                        >
                          {{ schoolYearInfo(item.status).name }}
                        </v-icon>
                      </template>
                      <span>{{ schoolYearInfo(item.status).info }}</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col cols="12" class="mt-2 text-subtitle-1">
                {{ $t("schedule.day") }}
              </v-col>
              <v-col cols="12" class="mb-10">
                <v-row justify="space-around">
                  <v-checkbox
                    v-model="bodyRequest.dow"
                    :label="$t('app.days')[0]"
                    :value="1"
                  />
                  <v-checkbox
                    v-model="bodyRequest.dow"
                    :label="$t('app.days')[1]"
                    :value="2"
                  />
                  <v-checkbox
                    v-model="bodyRequest.dow"
                    :label="$t('app.days')[2]"
                    :value="3"
                  />
                  <v-checkbox
                    v-model="bodyRequest.dow"
                    :label="$t('app.days')[3]"
                    :value="4"
                  />
                  <v-checkbox
                    v-model="bodyRequest.dow"
                    :label="$t('app.days')[4]"
                    :value="5"
                  />
                  <v-checkbox
                    v-model="bodyRequest.dow"
                    :label="$t('app.days')[5]"
                    :value="6"
                  />
                  <v-checkbox
                    v-model="bodyRequest.dow"
                    :label="$t('app.days')[6]"
                    :value="0"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <TimePicker
                  :label="$t('schedule.from_hour')"
                  outlined
                  dense
                  @value="v => (bodyRequest.start_time = v)"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <TimePicker
                  :label="$t('schedule.to_hour')"
                  :min="bodyRequest.start_time"
                  :isDisabled="!bodyRequest.start_time"
                  outlined
                  dense
                  @value="v => (bodyRequest.end_time = v)"
                />
              </v-col>
              <v-divider class="my-3" />
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="mr-2"
            outlined
            color="primary"
            dark
            @click="closeDialog"
            >{{ $t("app.back") }}</v-btn
          >
          <v-btn
            color="gradient-primary"
            depressed
            dark
            :loading="loadingSubmit"
            @click="submit"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { get_class_list, get_grade_list } from "@/api/admin/schoolClass";
import { setLessonExtra } from "@/api/admin/academic/schedule";
export default {
  props: {
    open: Boolean,
    idSchoolYearActive: Number,
    schoolYearSelected: Number,
    listSchoolYear: Array
  },
  components: {
    TimePicker: () => import("@/components/TimePicker")
  },
  watch: {
    open(value) {
      if (value) {
        this.bodyRequest.class = [];
        this.radio = "all";
        this.getGrade(true);
        this.idSchoolYear = this.schoolYearSelected;
      }
    },
    radio(value) {
      if (value === "some") {
        this.getGrade();
        this.bodyRequest.class = [];
        this.$refs.form.resetValidation();
      } else {
        this.idGrade = 0;
      }
    }
  },
  data: () => ({
    idGrade: 0,
    radio: "all",
    bodyRequest: {
      name: "",
      class: [],
      dow: [],
      start_time: "",
      end_time: ""
    },
    listGrade: [],
    listClass: [],
    loadingClass: false,
    loadingGrade: false,
    loadingSubmit: false,
    allListClass: [],
    idSchoolYear: 0
  }),
  methods: {
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      });
    },
    closeDialog() {
      this.resetForm();
      this.$emit("close");
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.bodyRequest.dow.length === 0) {
          this.snackBar(true, this.$i18n.t("schedule.msg_dialog_event"));
        } else {
          this.loadingSubmit = true;
          if (this.radio === "all") this.bodyRequest.class = this.allListClass;
          await setLessonExtra({
            ...this.bodyRequest,
            school_year: this.idSchoolYear
          })
            .then(res => {
              if (res.data.code === 1) {
                this.closeDialog();
                this.snackBar(
                  false,
                  this.$i18n.t("schedule.msg_response.success_create_event")
                );
              } else {
                this.snackBar(true, res.data.message);
              }
            })
            .catch(err => {
              this.snackBar(true, err);
            });
          this.loadingSubmit = false;
        }
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    getGrade(getAllClass) {
      this.loadingGrade = true;
      get_grade_list()
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            if (getAllClass) {
              this.getClass(
                r.map(d => d.grade),
                true
              );
            } else {
              this.listGrade = r;
            }
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingGrade = false;
        })
        .catch(err => {
          this.loadingGrade = false;
          this.snackBar(true, err);
        });
    },
    getClass(grade, isMaster) {
      this.loadingClass = true;
      get_class_list(
        {
          grade: Array.isArray(grade) ? grade : [grade],
          school_year: [this.idSchoolYearActive]
        },
        isMaster
      )
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            if (isMaster) {
              this.allListClass = r.map(d => d.id);
            } else {
              this.listClass = r;
            }
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingClass = false;
        })
        .catch(err => {
          this.loadingClass = false;
          this.snackBar(true, err);
        });
    }
  }
};
</script>
