<template>
  <div>
    <div v-if="$vuetify.breakpoint.lgAndUp">
      <div class="d-flex align-center">
        <v-select
          v-model="modelIdSchoolYear"
          :items="listSchoolYear"
          :label="$t('master_data.student.school_year')"
          :item-text="item => `${item.start_year} / ${item.end_year}`"
          :loading="loadingSchoolYear"
          item-value="id"
          outlined
          hide-details
          dense
          class="mr-2 select-150 d-inline-block"
        >
          <template #item="{ item }">
            <span class="caption">
              {{ item.start_year }} / {{ item.end_year }}, semester
              {{ item.semester }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                  >{{ schoolYearInfo(item.status).name }}</v-icon
                >
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
        </v-select>
        <v-select
          v-model="idGrade"
          :items="listGrade"
          :label="$t('schedule.choose_grade')"
          :loading="loadingGrade"
          item-value="id"
          item-text="grade"
          outlined
          hide-details
          dense
          class="mr-2 select-150 d-inline-block"
          @change="changeGrade"
        />
        <v-select
          v-model="modelClass"
          :items="listClass"
          :label="$t('academic.schedule_data.type_of_class')"
          :loading="loadingClass"
          item-value="id"
          item-text="name"
          outlined
          hide-details
          dense
          class="mr-2 select-150 d-inline-block"
        />
        <v-spacer />
        <div class="d-flex">
          <v-btn
            :disabled="
              loadingReset || loadingClear || listSchoolYear.length === 0
                ? true
                : false
            "
            class="gradient-primary caption mr-2"
            depressed
            dark
            dense
            @click="openModalEvent"
            >{{ $t("schedule.master_event") }}
            <v-icon class="ml-2">mdi-calendar-text</v-icon>
          </v-btn>
          <v-btn
            :disabled="loadingReset || loadingClear ? true : false"
            class="gradient-primary caption mr-2"
            depressed
            dark
            dense
            @click="openModalMaster"
            >{{ $t("schedule.master_hour") }}
            <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn
            :disabled="!modelClass || loadingClear ? true : false"
            class="mr-2"
            color="primary"
            outlined
            depressed
            dense
            @click="confirmGenerate = true"
            >{{ $t("schedule.generate") }}
            <v-icon class="ml-2">mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            :disabled="!modelClass || loadingReset ? true : false"
            class="mr-2"
            color="primary"
            outlined
            depressed
            dense
            @click="confirmClear = true"
            >{{ $t("schedule.clear") }}
            <v-icon class="ml-2">mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div v-else class="d-flex align-center">
      <v-btn color="primary" @click="sheet = !sheet" depressed>
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
      <v-spacer />
      <v-speed-dial
        v-model="menuSubject"
        direction="bottom"
        right
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="menuSubject" depressed color="primary" dark>
            <v-icon v-if="menuSubject">mdi-close</v-icon>
            <v-icon v-else>mdi-menu-down-outline</v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" depressed fab dense small @click="openModalMaster">
              <v-icon color="primary">mdi-plus-box</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("schedule.master_hour") }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="
                loadingReset || loadingClear || listSchoolYear.length === 0
                  ? true
                  : false
              "
              depressed
              fab
              dense
              small
              @click="openModalEvent"
            >
              <v-icon color="primary">mdi-calendar-text</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("schedule.master_event") }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="!modelClass ? true : false"
              depressed
              fab
              small
              dense
              @click="confirmGenerate = true"
            >
              <v-icon color="primary">mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("schedule.generate") }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="!modelClass ? true : false"
              depressed
              fab
              small
              dense
              @click="confirmClear = true"
            >
              <v-icon color="primary">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("schedule.clear") }}</span>
        </v-tooltip>
      </v-speed-dial>
    </div>

    <v-divider class="my-4" />

    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center pa-6">
        <v-row>
          <v-select
            v-model="modelIdSchoolYear"
            :items="listSchoolYear"
            :label="$t('master_data.student.school_year')"
            :item-text="
              item =>
                `${item.start_year} / ${item.end_year}, semester ${item.semester}`
            "
            :loading="loadingSchoolYear"
            item-value="id"
            outlined
            hide-details
            dense
            class="mr-2 mt-3"
          >
            <template #item="{ item }">
              <span class="caption">
                {{ item.start_year }} / {{ item.end_year }}, semester
                {{ item.semester }}
              </span>
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="schoolYearInfo(item.status).color"
                    small
                    class="ml-1"
                    >{{ schoolYearInfo(item.status).name }}</v-icon
                  >
                </template>
                <span>{{ schoolYearInfo(item.status).info }}</span>
              </v-tooltip>
            </template>
          </v-select>
          <v-select
            v-model="idGrade"
            :items="listGrade"
            :label="$t('schedule.choose_grade')"
            :loading="loadingGrade"
            item-value="id"
            item-text="grade"
            outlined
            hide-details
            dense
            class="mr-2 mt-3"
            @change="changeGrade"
          />
          <v-select
            v-model="modelClass"
            :items="listClass"
            :label="$t('academic.schedule_data.type_of_class')"
            :loading="loadingClass"
            item-value="id"
            item-text="name"
            outlined
            hide-details
            dense
            class="mr-2 mt-3"
          />
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
    <DialogMaster
      :open="modalMaster"
      :close="() => (modalMaster = false)"
      :idSchoolYear="modelIdSchoolYear"
    />
    <DialogEvent
      :open="modalEvent"
      :idSchoolYearActive="idSchoolYearActive"
      :schoolYearSelected="modelIdSchoolYear"
      :listSchoolYear="listSchoolYear"
      @close="() => (modalEvent = false)"
    />

    <ModalConfirm
      :content="$t('schedule.confirm_generate_content')"
      :close="() => (this.confirmGenerate = false)"
      :loading="loadingReset"
      :isOpen="confirmGenerate"
      :save="generateLesson"
    />

    <ModalConfirmClear
      :content="
        $t('schedule.confirm_clear_content', { class: this.isnameClass })
      "
      :close="() => (this.confirmClear = false)"
      :loading="loadingClear"
      :isOpen="confirmClear"
      :save="clearLesson"
    />
  </div>
</template>

<script>
import {
  get_school_year_list,
  get_class_list,
  get_grade_list
} from "@/api/admin/schoolClass";
import {
  resetLessonSchedule,
  clearLessonSchedule
} from "@/api/admin/academic/schedule";
import DialogMaster from "./DialogMaster";
import DialogEvent from "./DialogEvent";

export default {
  props: {
    idClass: Number,
    idSchoolYear: Number
  },
  created() {
    this.getSchoolYear();
    this.getGrade();
  },
  watch: {
    modalEvent(value) {
      if (!value && this.modelClass) {
        this.getDataSchedule();
      }
    },
    modelClass(value) {
      var found = this.listClass.find(function(classname) {
        if (classname.id == value) {
          return classname;
        }
      });
      this.isnameClass = found.name;
    },
    idSchoolYear() {
      this.changeGrade(this.idGrade);
    }
  },
  computed: {
    modelClass: {
      set(newValue) {
        this.setClass(newValue);
      },
      get() {
        return this.idClass;
      }
    },
    modelIdSchoolYear: {
      set(newValue) {
        this.setIdSchoolYear(newValue);
      },
      get() {
        return this.idSchoolYear;
      }
    }
  },
  components: {
    DialogMaster,
    DialogEvent,
    ModalConfirm: () => import("@/components/ModalConfirm"),
    ModalConfirmClear: () => import("@/components/ModalConfirm")
  },
  data() {
    return {
      confirmClear: false,
      confirmGenerate: false,
      loadingReset: false,
      loadingClear: false,
      modalMaster: false,
      modalEvent: false,
      close: false,
      menuSubject: false,
      listSchoolYear: [],
      idSchoolYearActive: 0,
      listGrade: [],
      idGrade: 0,
      isnameClass: "",
      listClass: [],
      loadingSchoolYear: false,
      loadingGrade: false,
      loadingClass: false,
      sheet: false,
      fields: {
        Class: "kelas",
        "NIP Homeroom Teacher": "no_pendaftaran",
        "Name Homeroom Teacher": "wali",
        "NIP Conselor": "no_kartu",
        "Conselor Name": "nama",
        "Total Students": "id"
      },
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "homeroom_teacher" : ""
    };
  },
  methods: {
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    generateLesson() {
      this.loadingReset = true;
      resetLessonSchedule({
        class: this.modelClass,
        school_year: this.modelIdSchoolYear
      })
        .then(res => {
          if (res.data.code === 1) {
            this.snackBar(
              false,
              this.$i18n.t("schedule.msg_response.success_generate_schedule")
            );
            this.confirmGenerate = false;
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(err => {
          console.error("generateLesson()\n", err);
        })
        .finally(() => {
          this.loadingReset = false;
          this.confirmGenerate = false;
          this.getDataSchedule();
        });
    },
    clearLesson() {
      this.loadingClear = true;
      clearLessonSchedule({
        class: this.modelClass,
        school_year: this.modelIdSchoolYear
      })
        .then(res => {
          if (res.data.code === 1) {
            this.snackBar(
              false,
              this.$i18n.t("schedule.msg_response.success_clear_schedule")
            );
            this.confirmClear = false;
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(err => {
          console.log("clearLesson()\n", err);
        })
        .finally(() => {
          this.loadingClear = false;
          this.getDataSchedule();
        });
    },
    getDataSchedule() {
      this.$emit("getSchedule", true);
    },
    setClass(value) {
      this.$emit("setIdClass", value);
    },
    setIdSchoolYear(value) {
      this.$emit("setIdSchoolYear", value);
    },
    openModalMaster() {
      this.modalMaster = !this.modalMaster;
    },
    openModalEvent() {
      this.modalEvent = !this.modalEvent;
    },
    changeGrade(grade) {
      if (grade) {
        this.loadingClass = true;
        get_class_list({
          grade: [grade],
          school_year: [this.modelIdSchoolYear],
          type: this.typeList.toUpperCase()
        })
          .then(res => {
            if (res.data.code == 1) {
              let r = res.data.data;
              this.listClass = r;
            } else {
              this.snackBar(true, res.data.message);
            }
          })
          .catch(err => {
            console.error("changeGrade(grade)\n", err);
          })
          .finally(() => {
            this.loadingClass = false;
          });
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    getGrade() {
      this.loadingGrade = true;
      get_grade_list({ type: this.typeList })
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listGrade = r;
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(err => {
          console.error("getGrade()\n", err);
        })
        .finally(() => {
          this.loadingGrade = false;
        });
    },
    getSchoolYear() {
      this.loadingSchoolYear = true;
      get_school_year_list(false)
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listSchoolYear = r;
            let schoolYearActive = r.find(d => d.status === 1)?.id;
            if (schoolYearActive) {
              this.modelIdSchoolYear = schoolYearActive;
              this.idSchoolYearActive = schoolYearActive;
            }
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(err => {
          console.error("getSchoolYear()\n", err);
        })
        .finally(() => {
          this.loadingSchoolYear = false;
        });
    }
  }
};
</script>
